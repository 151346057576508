import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchCounty' })
export class SearchCounty implements PipeTransform {
  public transform(counties: any[], searchText: any): any {
    if (searchText == null || counties == null) {
      return counties;
    }
    return counties.filter(county => county.text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }
}