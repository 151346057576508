import { HttpHeaders } from '@angular/common/http';
import { IUserCredentials } from './../models/userCredentials.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
    providedIn: 'root'
  })

export class AuthService {

    constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {
    }

    async login(userName: string, password: string ) {

        return this.http.post<IUserCredentials>(environment.authApiUrl,
            { userName, password })
            .subscribe(res => {
                const token = (res as any).token;
                this.setSession(token);
            });
    }

    private setSession(authResult) {
        const tokenPayload = JSON.stringify(this.jwtHelper.isTokenExpired(authResult));

        const expiresAt = new Date().setSeconds(authResult.expiresIn);

        sessionStorage.setItem('id_token', authResult);
    }

    logout() {
        sessionStorage.removeItem('id_token');
    }

    public isLoggedIn() {
        return !this.isLoggedOut();
    }

    public getToken() {
        return sessionStorage.getItem('id_token');
      }

    isLoggedOut() {
        return this.jwtHelper.isTokenExpired(this.getToken());
    }
}
