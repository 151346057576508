import {Directive,HostListener} from '@angular/core'

@Directive({
selector: '[dobMask]'
})
export class DobDirective {

@HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');

    if (trimmed.length > 10) {
    trimmed = trimmed.substr(0, 10);
    }


    trimmed = trimmed.replace(/-/g,'');

    let numbers = [];
    
    numbers.push(trimmed.substr(0,4));
    if(trimmed.substr(4,2)!=="")
    numbers.push(trimmed.substr(4,2));
    if(trimmed.substr(6,2)!="")
    numbers.push(trimmed.substr(6,4));


    input.value = numbers.join('-');

  }
}