import { Component, ChangeDetectorRef } from "@angular/core";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormBuilder,
  FormGroup,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { RegisterTeacherService } from "../app/services/registerTeacher.service";
import { AuthService } from "./services/auth.service";
import { IRegisterTeacher } from "../app/models/registerTeacher.model";
import { ITeacherFormData } from "../app/models/teacherFormData.model";
import { MatDialog } from "@angular/material/dialog";
import { variable } from "@angular/compiler/src/output/output_ast";
import { ErrorStateMatcher } from "@angular/material/core";
import { environment } from "environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  beforeViewInit = true;
  // TeacherForm: FormGroup;
  FormGroupDirective: FormGroupDirective;
  browser: boolean = false;
  IRegisterTeacher: IRegisterTeacher;

  TeacherForm = new FormGroup({
    schools: new FormControl("", [Validators.required]),
    occupations: new FormControl("", [Validators.required]),
    subjects: new FormControl("", [Validators.required]),
    employers: new FormControl("", [Validators.required]),
    date_of_birth: new FormControl("", [Validators.required]),
    counties: new FormControl("", [Validators.required]),
    genders: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    recaptcha: new FormControl("", [Validators.required]),
  });

  showDiv = {
    previous: true,
    next: false,
  };

  buttonVisibility: string = "none";
  reCaptchaVisibility: string = "block";
  recaptchaResponse: string;
  recaptchaDataSize: string = "normal";

  matcher = new MyErrorStateMatcher();


  constructor(
    private RegisterTeacherService: RegisterTeacherService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private AuthService: AuthService
  ) {
    // this.createForm();
    AuthService.login(environment.authApiUser, environment.authApiUserPwd);
  }

  countyList = [
    { id: 96063333, text: "Blekinge län" },
    { id: 96063334, text: "Dalarnas län" },
    { id: 96063335, text: "Gotlands län" },
    { id: 96063336, text: "Gävleborgs län" },
    { id: 96063337, text: "Hallands län" },
    { id: 96063338, text: "Jämtlands län" },
    { id: 96063339, text: "Jönköpings län" },
    { id: 96063340, text: "Kalmar län" },
    { id: 96063341, text: "Kronobergs län" },
    { id: 96063342, text: "Norrbottens län" },
    { id: 96063343, text: "Skåne län" },
    { id: 96063344, text: "Stockholms län" },
    { id: 96063345, text: "Södermanlands län" },
    { id: 96063346, text: "Uppsala län" },
    { id: 96063347, text: "Värmlands län" },
    { id: 96063348, text: "Västerbottens län" },
    { id: 96063349, text: "Västernorrlands län" },
    { id: 96063350, text: "Västmanlands län" },
    { id: 96063351, text: "Västra Götalands län" },
    { id: 96063352, text: "Örebro län" },
    { id: 96063353, text: "Östergötlands län" },
  ];
  genderList = [
    { id: "m", text: "Man" },
    { id: "f", text: "Kvinna" },
    { id: "u", text: "Annat" },
  ];
  experienceList = [
    { id: 79390211, text: "0-3 år" },
    { id: 79390212, text: "4-9 år" },
    { id: 79390213, text: "10-19 år" },
    { id: 79390214, text: "20 år eller mer" },
    { id: 79390215, text: "Vet ej" },
  ];
  employerList = [
    { id: 96058360, text: "Kommun" },
    { id: 96058361, text: "Region/Landsting" },
    { id: 96058362, text: "Statlig" },
    { id: 96058363, text: "Privat/Enskild" },
  ];
  schoolList = [
    { id: 96058329, text: "Förskola" },
    { id: 96058330, text: "Fritidshem" },
    { id: 96058331, text: "Förskoleklass" },
    { id: 96058332, text: "Grundskola, årskurs 1-3" },
    { id: 96058333, text: "Grundskola, årskurs 4-6" },
    { id: 96058334, text: "Grundskola, årskurs 7-9" },
    { id: 96063260, text: "Grundsärskola" },
    { id: 96063261, text: "Gymnasium" },
    { id: 96063262, text: "Gymnasiesärskola" },
    { id: 96063263, text: "Vuxenutbildning" },
    { id: 96063264, text: "Högskola" },
    { id: 96063265, text: "Universitet" },
    { id: 96063266, text: "Folkhögskola" },
    { id: 96063267, text: "Annat" },
  ];
  occupationList = [
    { id: 96214007, text: "Barnskötare" },
    { id: 96214008, text: "Förskollärare" },
    { id: 96214009, text: "Fritidspedagog/lärare i fritidshem" },
    { id: 96214010, text: "Lärare i förskoleklass" },
    { id: 96214011, text: "Lärare, grundskola" },
    { id: 96214012, text: "Lärare, grundsärskola" },
    { id: 96214013, text: "Speciallärare" },
    { id: 96214014, text: "Specialpedagog" },
    { id: 96214015, text: "Resurspersonal/elevassistent" },
    { id: 96214017, text: "Lärare, gymnasielärare" },
    { id: 96214018, text: "Lärare, gymnasiesärskola" },
    { id: 96214019, text: "Lärare, vuxenutbildning" },
    { id: 96214020, text: "SFI-lärare" },
    { id: 96214021, text: "Högskolelärare" },
    { id: 96214022, text: "Universitetslärare" },
    { id: 96214023, text: "Lärare på folkhögskola" },
    { id: 96214024, text: "Skolledare/rektor/biträdande rektor" },
    { id: 96214025, text: "Annat" },
  ];

  subjectList = [
    { id: 96058341, text: "Arbetar INTE SOM LÄRARE på grund- eller gymnasieskola" },
    { id: 96058342, text: "Svenska eller svenska som andraspråk" },
    { id: 96058343, text: "Engelska" },
    { id: 96058344, text: "Matematik" },
    { id: 96058345, text: "Bild" },
    { id: 96058346, text: "Hem- och konsumentkunskap" },
    { id: 96058347, text: "Idrott och hälsa" },
    { id: 96058348, text: "Musik" },
    { id: 96058349, text: "Slöjd" },
    { id: 96058350, text: "Biologi" },
    { id: 96058351, text: "Kemi" },
    { id: 96058352, text: "Teknik" },
    { id: 96058353, text: "Geografi" },
    { id: 96058354, text: "Historia" },
    { id: 96058355, text: "Religionskunskap" },
    { id: 96058356, text: "Samhällskunskap" },
    { id: 96058357, text: "Moderna språk" },
    { id: 96058358, text: "Annat ämne på teoretiskt gymnasium" },
    { id: 96058359, text: "Annat ämne på yrkesförberedande gymnasium" },
  ];

  checkBrowser() {
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge === true) {
      this.browser = true;
    }
  }

  handleSuccess(token: string) {
    this.buttonVisibility = "block";
    this.reCaptchaVisibility = "none";
    this.recaptchaResponse = token;
  }

  handleExpire() {
    this.buttonVisibility = "none";
    this.reCaptchaVisibility = "block";
  }

  title = "teacher-form";
  siteKey: string = environment.recaptchaKey;

  onSubmit(data) {
    let teacher: IRegisterTeacher = {
      email_address: this.TeacherForm.get("email").value,
      gender: this.TeacherForm.get("genders").value,
      date_of_birth: new Date(
        this.TeacherForm.get("date_of_birth").value as string
      ),
      variables: new Array(),
      recaptchaResponse: this.recaptchaResponse,
    };

    for (let i of this.TeacherForm.get("schools").value) {
      let id: number = this.schoolList
        .filter((s) => s.text === (i as string))
        .map((s) => s.id)[0];

      teacher.variables.push(id);
    }

    let id: number = this.occupationList
      .filter(
        (s) => s.text === (this.TeacherForm.get("occupations").value as string)
      )
      .map((s) => s.id)[0];

    teacher.variables.push(id);

    for (let i of this.TeacherForm.get("subjects").value) {
      let id: number = this.subjectList
        .filter((s) => s.text === (i as string))
        .map((s) => s.id)[0];

      teacher.variables.push(id);
    }

    let empId: number = this.employerList
      .filter(
        (s) => s.text === (this.TeacherForm.get("employers").value as string)
      )
      .map((s) => s.id)[0];

    teacher.variables.push(empId);

    let countyId: number = this.countyList
      .filter(
        (s) => s.text === (this.TeacherForm.get("counties").value as string)
      )
      .map((s) => s.id)[0];

    teacher.variables.push(countyId);

    this.RegisterTeacherService.postTeacherRegistration(teacher).subscribe(
      (result) => {
        (data = this.TeacherForm.value), result;
        if (result) {
          if (result.code === 201) {
            this.dialog.open(ConfirmationDialog);
            setTimeout(() => {
              this.dialog.closeAll();
              this.showDiv.previous = false;
              this.showDiv.next = true;
              this.TeacherForm.reset();
            }, 3000);
            console.log(data);
          }
        } else {
          this.dialog.open(ErrorDialog);
          setTimeout(() => {
            this.dialog.closeAll();
          }, 3000);
        }
      }
    );
  }

  ngOnInit() {
    this.checkBrowser();
    if (window.innerWidth <= 480) {
      this.recaptchaDataSize = "compact";
    }
  }
}

@Component({
  selector: "confirmation-dialog",
  templateUrl: "confirmation-dialog.html",
  styleUrls: ["./app.component.scss"],
})
export class ConfirmationDialog {
  constructor(private dialogRef: MatDialog) {}
  onClick() {
    this.dialogRef.closeAll();
  }
}

@Component({
  selector: "error-dialog",
  templateUrl: "error-dialog.html",
  styleUrls: ["./app.component.scss"],
})
export class ErrorDialog {
  constructor(private dialogRef: MatDialog) {}
  onClick() {
    this.dialogRef.closeAll();
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
