import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, ConfirmationDialog, ErrorDialog } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

//Angular material
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { DobDirective } from "./mask";
import { MatDialogModule } from "@angular/material/dialog";
import { SearchCounty } from "./searchCounty.pipe";

//Recaptcha
import { NgxCaptchaModule } from "ngx-captcha";

@NgModule({
  declarations: [
    AppComponent,
    DobDirective,
    ConfirmationDialog,
    SearchCounty,
    ErrorDialog,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatListModule,
    MatExpansionModule,
    MatDialogModule,
  ],
  providers: [SearchCounty, { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService],
  entryComponents: [ConfirmationDialog, ErrorDialog],
  bootstrap: [AppComponent],
})
export class AppModule {}
