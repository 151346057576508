<div>
  <img
    class="logo"
    src="https://rs.miprocloud.net/PortalDataService/Content/2gwFA7yHo0x/Lararpanelen_Logo.jpg"
  />
  <mat-card class="card">
    <mat-card-content id="form" *ngIf="showDiv.previous">
      <h1 class="text">Skapa konto</h1>
      <div class="formControl">
        <form
          [formGroup]="TeacherForm"
          autocomplete="off"
          (ngSubmit)="onSubmit(TeacherForm.value)"
        >
          <br />
          <mat-form-field appearance="fill">
            <mat-label>Var arbetar du?</mat-label>
            <mat-select formControlName="schools" multiple>
              <mat-select-trigger>
                {{
                  this.TeacherForm.get("schools").value
                    ? this.TeacherForm.get("schools").value[0]
                    : ""
                }}
                <span
                  *ngIf="this.TeacherForm.get('schools').value?.length > 1"
                  class="additional-selection"
                >
                  (+{{ this.TeacherForm.get("schools").value.length - 1 }}
                  {{
                    this.TeacherForm.get("schools").value?.length === 2
                      ? "annan"
                      : "andra"
                  }})
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let school of schoolList"
                [value]="school.text"
                >{{ school.text }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field appearance="fill">
            <mat-label>Vad jobbar du som?</mat-label>
            <mat-select formControlName="occupations" multiple="false">
              <mat-select-trigger>
                {{
                  this.TeacherForm.get("occupations").value
                    ? this.TeacherForm.get("occupations").value
                    : ""
                }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let occupation of occupationList"
                [value]="occupation.text"
                >{{ occupation.text }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field appearance="fill">
            <mat-label>I vilka ämnen undervisar du?</mat-label>
            <mat-select formControlName="subjects" multiple>
              <mat-select-trigger>
                {{
                  this.TeacherForm.get("subjects").value
                    ? this.TeacherForm.get("subjects").value[0]
                    : ""
                }}
                <span
                  *ngIf="this.TeacherForm.get('subjects').value?.length > 1"
                  class="additional-selection"
                >
                  (+{{ this.TeacherForm.get("subjects").value.length - 1 }}
                  {{
                    this.TeacherForm.get("subjects").value?.length === 2
                      ? "annan"
                      : "andra"
                  }})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let sub of subjectList" [value]="sub.text">{{
                sub.text
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field appearance="fill">
            <mat-label>Vem är du anställd av?</mat-label>
            <mat-select formControlName="employers" multiple="false">
              <mat-select-trigger>
                {{
                  this.TeacherForm.get("employers").value
                    ? this.TeacherForm.get("employers").value
                    : ""
                }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let employer of employerList"
                [value]="employer.text"
                >{{ employer.text }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field appearance="fill">
            <mat-label>I vilket län ligger din arbetsplats?</mat-label>
            <mat-select formControlName="counties" multiple="false">
              <mat-select-trigger>
                {{
                  this.TeacherForm.get("counties").value
                    ? this.TeacherForm.get("counties").value
                    : ""
                }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let county of countyList"
                [value]="county.text"
                >{{ county.text }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />

          <mat-form-field>
            <mat-label>Födelsedatum</mat-label>
            <input
              matInput
              type="tel"
              class="date"
              formControlName="date_of_birth"
              noDatePicker
              dobMask
              maxlength="10"
              placeholder="ÅÅÅÅ-MM-DD"
            />
            <mat-error
              *ngIf="this.TeacherForm.get('date_of_birth').hasError('required')"
            >
              Födelsedatum <strong>krävs</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              [errorStateMatcher]="matcher"
              placeholder="Ex. pat@example.com"
            />
            <mat-error
              *ngIf="
                this.TeacherForm.get('email').hasError('email') &&
                !this.TeacherForm.get('email').hasError('required')
              "
            >
              Vänligen mata in en giltig email adress
            </mat-error>
            <mat-error
              *ngIf="this.TeacherForm.get('email').hasError('required')"
            >
              Email <strong>krävs</strong>
            </mat-error>
          </mat-form-field>
          <br />
          <br />
          <mat-radio-group
            name="gender"
            formControlName="genders"
            aria-label="Select an option"
          >
            <mat-radio-button
              *ngFor="let gender of genderList"
              [value]="gender.id"
              ><span class="radio-button-span">
                {{ gender.text }}
              </span></mat-radio-button
            >
          </mat-radio-group>
          <br />
          <br />
          <ngx-recaptcha2
            #captchaElem
            [siteKey]="siteKey"
            formControlName="recaptcha"
            (success)="handleSuccess($event)"
            (expire)="handleExpire()"
            [style.display]="reCaptchaVisibility"
            data-size="{{ recaptchaDataSize }}"
          >
          </ngx-recaptcha2>
          <br />

          <button
            mat-raised-button
            type="submit"
            [disabled]="!TeacherForm.valid"
            class="button"
            [style.display]="buttonVisibility"
          >
            Registrera
          </button>
        </form>
      </div>
    </mat-card-content>
    <mat-card-content id="tack" *ngIf="showDiv.next">
      <p>Tack för din registrering!</p>
      <p>
        Du kommer nu att få ett mail (kolla gärna skräpposten) med en länk.
        Klicka på länken för att bekräfta ditt medlemskap.
      </p>
      <p>
        Därefter behöver du inte göra någonting, bara invänta kommande
        undersökningar.
      </p>
    </mat-card-content>
  </mat-card>
</div>
