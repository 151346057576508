import { Injectable, EventEmitter } from "@angular/core";
import { Subject, Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, finalize, tap } from "rxjs/operators";
import { IRegisterTeacher } from "../models/registerTeacher.model";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})

export class RegisterTeacherService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  postTeacherRegistration(
    RegisterTeacher: IRegisterTeacher
  ): Observable<IRegisterTeacher> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + this.authService.getToken()
    });
    let options = { headers: headers };

    return this.http
      .post<IRegisterTeacher>(environment.apiUrl, RegisterTeacher, options)
      .pipe(
        tap(() => this.authService.logout()),
        catchError(
          this.handleError<IRegisterTeacher>("postTeacherRegistration", null)
        )
      );
  }
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
